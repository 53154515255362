<template>
  <div class="p-6 pr-6 all">
    <div v-if="!activeDetail">
      <div
        class="flex items-center"
      >
        <div class="w-1/2 flex text-left text-c22 items-center font-c8">
          Commerces / Utilisateurs
        </div>

        <div class="w-1/2 flex justify-end">
          <inputo
            :icon="icons.search"
            @info="retourSearch"
            height="50px"
            placeholder="Rechercher..."
            class="w-1/2"
          />
        </div>
      </div>

      <div class="mt-6">
        <global-view
          v-if="!reloadSession"
          :search="recherche"
          @info="retourEtape"
        />
      </div>
    </div>
  </div>
</template>

<script>
import search from '../../assets/icons/search.svg'
import globalView from '../../component/cdevs/utilisateur/globalData'
import inputo from '../../component/helper/form/input'

export default {
  name: "Index",

  components: {
    globalView,
    inputo
  },

  data () {
    return {
      icons: {
        search
      },
      reloadSession: false,
      activeDetail: false,
      selectedItem: null,
      user: null,
      recherche: null,
    }
  },

  methods: {
    retourSearch (answer) {
      this.recherche = answer
    },

    retourEtape (answer) {
      this.selectedItem = answer
      this.activeDetail = true
    },

    retourBack (a){
      this.activeDetail = a
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  fill: white;
}
.all{
  min-height: 100vh;
}
</style>
